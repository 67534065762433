<template>
  <div>
    <el-form :inline="true" :model="form">
      <el-form-item label="商户名称:">
        <!-- <el-input v-model="form.merchant_no" placeholder="请输入商户名称" /> -->
        <el-select v-model="form.merchant_no" clearable filterable placeholder="请选择商户名称" style="width: 300px">
          <el-option
            v-for="item in merchantOptions"
            :key="item.merchant_no"
            :label="item.merchant_name"
            :value="item.merchant_no"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期:"><DatePicker v-model="form.time_space" /> </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="init">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" border>
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column label="商户名称">
        <template slot-scope="{ row }">{{ row.merchant_short_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="支付金额">
        <template slot-scope="{ row }">{{ row.pay_currency || '-' }} {{ row.pay_amount || '-' }}</template>
      </el-table-column>
      <el-table-column label="支付通道">
        <template slot-scope="{ row }"
          >{{ channel[row.channel] || '-' }}{{ region[row.region] || '-'
          }}{{ channel_type[row.channel_type] || '-' }}</template
        >
      </el-table-column>
      <el-table-column label="支付状态">
        <!-- 0未支付 1已支付 -1支付失败 -2已失效 -->
        <template slot-scope="{ row }">
          <template v-if="row.payment_status === '0'">未支付</template>
          <template v-else-if="row.payment_status === '1'">已支付</template>
          <template v-else-if="row.payment_status === '-1'">已支付</template>
          <template v-else-if="row.payment_status === '-2'">已失效</template>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" @init="init" ref="pagination" />
  </div>
</template>

<script>
import { merchantWater, merchantList } from '@/api/api'
import DatePicker from '@/components/date-picker'
import Pagination from '@/components/pagination'

export default {
  name: 'Bill', //商户流水
  components: { DatePicker, Pagination },
  data() {
    return {
      form: {
        merchant_no: '',
        time_space: [
          `${new Date().format('yyyy-MM-dd hh:mm:ss').split(' ')[0]} 00:00:00`,
          new Date().format('yyyy-MM-dd hh:mm:ss')
        ]
      },
      merchantOptions: [],
      tableData: [],
      total: 0,
      channel_type: {
        1: '线上',
        2: '线下'
      },
      channel: {
        ALIPAY: '支付宝',
        WECHATPAY: '微信',
        SUPAY: '支付宝（supay）',
        SICPAY: '云闪付',
        APOS: '银联POS'
      },
      region: {
        HK: '（香港钱包）',
        CN: '（跨境钱包）',
        AU: ''
      }
    }
  },
  mounted() {
    this.merchantList()
    this.init()
  },
  methods: {
    init() {
      const [pagination, time_space] = [this.$refs.pagination, this.form.time_space]
      const params = {
        page: pagination.page,
        limit: pagination.limit,
        merchant_no: this.form.merchant_no
      }
      if (time_space.length > 0) params.time_space = `${time_space[0]}~${time_space[1]}`
      merchantWater(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = res.data.count
        }
      })
    },
    // 商户列表
    merchantList() {
      merchantList().then((res) => {
        if (res.code === 200) {
          this.merchantOptions = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>



